import React, { useEffect, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import FileUploader from './components/FileUploader';
import './App.css';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

function App() {
  const [companies, setCompanies] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [showImporter, setShowImporter] = useState(false);

  useEffect(() => {
    async function fetchCompanies() {
      const companyCollection = collection(db, 'companies');
      const companySnapshot = await getDocs(companyCollection);
      const companyList = companySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      const companiesWithUrls = await Promise.all(companyList.map(async (company) => {
        try {
          const logoUrl = company.logo ? await getDownloadURL(ref(storage, company.logo)) : null;
          return { 
            ...company, 
            logoUrl,
            url: typeof company.url === 'object' ? company.url.text : company.url
          };
        } catch (error) {
          console.log("Error getting logo URL: ", error);
          return company;
        }
      }));

      // Sort companies by priority (higher priority first)
      const sortedCompanies = companiesWithUrls.sort((a, b) => {
        return (b.priority || 0) - (a.priority || 0);
      });

      setCompanies(sortedCompanies);

      // Flatten and get unique industries
      const allIndustries = Array.from(new Set(
        companyList.flatMap(company => {
          if (typeof company.industry === 'string') {
            try {
              const parsedIndustry = JSON.parse(company.industry);
              return Array.isArray(parsedIndustry) ? parsedIndustry : [parsedIndustry];
            } catch (e) {
              return [company.industry];
            }
          } else if (Array.isArray(company.industry)) {
            return company.industry;
          } else {
            return [];
          }
        }).filter(industry => industry.trim() !== '')
      ));

      setIndustries(allIndustries);
    }

    fetchCompanies();
  }, []);

  const handleCompanyClick = (url) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  const handleIndustryChange = (event) => {
    setSelectedIndustry(event.target.value);
  };

  const filteredCompanies = selectedIndustry 
    ? companies.filter(company => {
        if (typeof company.industry === 'string') {
          try {
            const parsedIndustry = JSON.parse(company.industry);
            if (Array.isArray(parsedIndustry)) {
              return parsedIndustry.includes(selectedIndustry);
            } else {
              return parsedIndustry === selectedIndustry;
            }
          } catch (e) {
            return company.industry === selectedIndustry;
          }
        } else if (Array.isArray(company.industry)) {
          return company.industry.includes(selectedIndustry);
        } else {
          return false;
        }
      })
    : companies;

    return (
      <div>
        <div className="title-banner">
          <div className="header-content">
            {/* Dropdown for selecting industry */}
            <div className="filter-section">
              <label htmlFor="industry-filter"></label>
              <select id="industry-filter" value={selectedIndustry} onChange={handleIndustryChange}>
                <option value="">Filter by Industry</option>
                {industries.map((industry, index) => (
                  <option key={index} value={industry}>
                    {industry}
                  </option>
                ))}
              </select>
            </div>
            
            <h1>Toledo Tech Directory</h1>
          </div>
        </div>
    
        <div className="container">
          <div id="company-list">
            {filteredCompanies.map((company) => (
              <div key={company.id} className="company" onClick={() => handleCompanyClick(company.url)}>
                {company.logoUrl ? (
                  <img src={company.logoUrl} alt={company.organization_name} title={company.organization_name} />
                ) : (
                  <div className="company-name">{company.organization_name}</div>
                )}
              </div>
            ))}
          </div>
    
          {/* FileUploader component - conditionally rendered */}
          {showImporter && (
            <div className="importer-section">
              <h2>Import Company Data</h2>
              <FileUploader />
            </div>
          )}
        </div>
      </div>
    );
    
}

export default App;



